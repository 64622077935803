.layout-profile-sidebar {
  padding: env(safe-area-inset-top, 20px) env(safe-area-inset-right, 20px)
    env(safe-area-inset-bottom, 20px) env(safe-area-inset-left, 20px);

  &.p-sidebar {
    width: 25rem;

    .p-sidebar-content {
      padding-left: 2rem;
      padding-right: 2rem;
    }
  }
}
