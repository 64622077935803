.layout-breadcrumb {
  ol {
    display: flex;
    align-items: center;
    margin: 0;
    padding: 0;
    list-style: none;
    gap: 1rem;
    flex-wrap: wrap;
    color: var(--text-color-secondary);

    li {
      font-weight: 600;
    }
  }
}

.content-breadcrumb {
  background-color: var(--surface-ground);
  z-index: 20;
  top: var(--safe-area-inset-top);
}
